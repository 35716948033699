<template>
  <div class="app-inplay">
    <!-- Inplay -->
    <div class="inplay-wrap">
      <!-- Leagues -->
      <div
        v-if="inplayWidth >= 1084"
        class="leagues-nav"
      >
        <section>
          <div class="leagues-wrap">
            <b-list-group>
              <b-list-group-item
                class="league"
                :active="selectedLeague === 'all'"
                @click="handleLeagues('all')"
              >
                <span class="league-symbol">
                  <feather-icon
                    icon="LayersIcon"
                  />
                </span>
                <span class="league-name">
                  전체
                </span>
                <b-badge
                  variant="dark"
                  class="league-count"
                >
                  {{ totalLeagues }}
                </b-badge>
              </b-list-group-item>
              <template
                v-if="!isLoading"
              >
                <b-list-group-item
                  v-for="(league, lIndex) of leagues"
                  :key="lIndex"
                  class="league"
                  :active="selectedLeague === league.name"
                  @click="handleLeagues(league.name)"
                >
                  <span
                    class="league-symbol"
                  >
                    <b-img
                      :src="handleImageUrl(league.name)"
                      @error="handleImageError"
                    />
                  </span>
                  <span class="league-name">
                    {{ league.name }}
                  </span>
                  <b-badge
                    variant="dark"
                    class="league-count"
                  >
                    {{ league.count }}
                  </b-badge>
                </b-list-group-item>
              </template>
              <template
                v-else
              >
                <b-list-group-item>
                  <div class="d-flex justify-content-center mt-3 mb-2">
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                  </div>
                </b-list-group-item>
              </template>
            </b-list-group>
          </div>
        </section>
      </div>

      <!-- Games -->
      <div class="games-main">
        <section>
          <!--  -->
          <b-card>
            <div class="games-nav">
              <!-- Sports -->
              <div
                v-if="false"
                class="games-sports"
              >
                <b-form-group>
                  <b-form-radio-group
                    v-model="selectedSports"
                    v-ripple.400="'rgba(200, 191, 199, 0.15)'"
                    :options="filteredSports"
                    buttons
                    button-variant="primary"
                    class="full-width"
                    @change="handleRadioSports($event)"
                  />
                </b-form-group>
              </div>
              <div>
                <b-form-radio-group
                  v-model="selectedSports"
                  v-ripple.400="'rgba(200, 191, 199, 0.15)'"
                  buttons
                  button-variant="primary"
                  style="width: 100%"
                  class="d-flex flex-wrap"
                  @change="handleRadioSports"
                >
                  <b-form-radio
                    v-for="option in filteredSports"
                    :key="option.value"
                    :value="option.value"
                    class="text-nowrap"
                    style="width: 90px; padding: 0.786rem 0.9rem;"
                  >
                    <img
                      v-if="option.value === 'soccer'"
                      src="./icon-soccer.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'basketball'"
                      src="./icon-basketball.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'baseball'"
                      src="./icon-baseball.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'tennis'"
                      src="./icon-tennis.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'volleyball'"
                      src="./icon-volleyball.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'football'"
                      src="./icon-football.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'hockey'"
                      src="./icon-hockey.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'esports'"
                      src="./icon-esports.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >

                    <span
                      class="text-nowrap"
                    > {{ option.text }}
                    </span>
                  </b-form-radio>
                </b-form-radio-group>

              </div>
              <!-- Search -->
              <div class="games-search">
                <b-form-group label-for="searchTextInput">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="searchTextInput"
                      v-model="crossFilter.searchText"
                      type="text"
                      placeholder="리그명 & 팀명"
                      @keyup="handleKeyupSearchText()"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </b-card>
        </section>
        <section>
          <b-card>
            <template
              v-if="paginatedItems.length > 0 "
            >
              <!-- Bonus -->
              <div class="bonus-wrap">
                <div class="btn-wrap">
                  <b-button
                    v-for="(event, eIndex) in filteredSetEvents"
                    :key="eIndex"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :class="{'cart-selected': filteredCartItems.eventItems.includes(event.eventName) }"
                    variant="outline-secondary"
                    @click="addCart('Bonus', event, event.bonusOdds)"
                  >
                    <span>
                      {{ formatValue(event.eventName) }}
                    </span>
                    <span>
                      {{ event.bonusOdds }}
                    </span>
                  </b-button>
                </div>
              </div>
              <div class="games-wrap">
                <div class="games">
                  <!-- Game List -->
                  <div
                    v-for="(game, gIndex) in paginatedItems"
                    :key="gIndex"
                    class="game"
                    :class="{ 'game-selected': game.eventId === selectedGame[selectedSports] }"
                    @click.stop="handleSelectedGame(game)"
                  >
                    <div class="game-header">
                      <!-- game-league -->
                      <div class="game-league">
                        <span
                          class="league-symbol"
                        >
                          <b-img
                            :src="handleImageUrl(game.leagueName)"
                            @error="handleImageError"
                          />
                        </span>
                        <span class="league-name text-truncate">{{ game.leagueName }}</span>
                      </div>
                    </div>
                    <div class="game-contents">
                      <!-- board-header -->
                      <div class="board-header">
                        <div class="period">
                          상태: {{ game.period }}
                        </div>
                        <div class="extra-options">
                          +{{ game.odds.length }} 추가 배팅옵션
                        </div>
                      </div>
                      <!-- board-contents -->
                      <div class="board-contents">
                        <div class="game-time">
                          <span>
                            {{ game | formatGameTime }}
                          </span>
                        </div>
                        <div class="game-score">
                          <span class="home-score">
                            {{ game.score ? game.score.split(':')[0] : 0 }}
                          </span>
                          <span class="vs">
                            VS
                          </span>
                          <span class="away-score">
                            {{ game.score ? game.score.split(':')[1] : 0 }}
                          </span>
                        </div>
                        <div
                          class="odds"
                        >
                          <!-- @click.stop -->
                          <div
                            class="btn-wrap"
                          >
                            <template v-if="filteredFullTimeResult(game.odds).length > 0">
                              <template
                                v-for="(part, pIndex) in filteredFullTimeResult(game.odds)"
                              >
                                <b-button
                                  :key="pIndex"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  :class="{ 'cart-selected': filteredCartItems.oddsItems.includes(part.id) }"
                                  variant="outline-secondary"
                                  @click="addCart(game, game.odds.find(value => value.id === 1777), part)"
                                >
                                  <span
                                    v-if="part.name.toLowerCase().includes('home')"
                                    v-b-tooltip.hover.bottom.v-danger="game.homeName"
                                    class="homeAway"
                                  >{{ game.homeName }}</span>
                                  <span class="point">{{ part.value_eu }}</span>
                                  <span
                                    v-if="part.name.toLowerCase().includes('away')"
                                    v-b-tooltip.hover.bottom.v-danger="game.awayName"
                                    class="homeAway"
                                  >{{ game.awayName }}</span>
                                </b-button>
                              </template>
                            </template>
                            <template v-else>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"

                                variant="outline-secondary"
                                disabled
                              >
                                <span
                                  v-b-tooltip.hover.bottom.v-danger="game.homeName"
                                  class="homeAway"
                                >{{ game.homeName }}</span>
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-secondary"
                                disabled
                              >
                                <span class="point"> VS </span>
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"

                                variant="outline-secondary"
                                disabled
                              >
                                <span
                                  v-b-tooltip.hover.bottom.v-danger="game.awayName"
                                  class="homeAway"
                                >{{ game.awayName }}</span>
                              </b-button>
                            </template>
                          </div>
                        </div>

                        <template
                          v-if="isMobile"
                        >
                          <hr>
                        </template>
                        <!-- games-sub -->
                        <div
                          v-if="isMobile && game.eventId === selectedGame[selectedSports]"
                          class="games-sub"
                          @click.stop
                        >
                          <sports-inplay-games-odds
                            ref="sportsInplayGamesOdds"
                            :selected-game="filteredSelectedGame"
                            :is-collapsed-odds="isCollapsedOdds"
                            :cart-items.sync="cartItems"
                            @handleCollapsedOdds="handleCollapsedOdds"
                            @addCart="addCart"
                          />
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- Pagination -->
                  <div
                    class="d-flex justify-content-center p-1"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                  <!-- .Pagination -->
                </div>
                <template v-if="!isMobile">
                  <div class="games-sub">
                    <sports-inplay-games-odds
                      ref="sportsInplayGamesOdds"
                      :selected-game="filteredSelectedGame"
                      :is-collapsed-odds="isCollapsedOdds"
                      :cart-items.sync="cartItems"
                      :collapsed-odds-temp.sync="collapsedOddsTemp"
                      @addCart="addCart"
                    />
                  </div>
                </template>
              </div>
            </template>
            <template v-else-if="isLoading">
              <div class="d-flex justify-content-center mt-3 mb-2">
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
            </template>
            <template v-else>
              <div
                v-if="searchText.trim().length > 0"
                class="search-result"
              >
                "{{ searchText }}" 조회결과 {{ paginatedItems.length }}건
              </div>
            </template>
          </b-card>
        </section>
      </div>

      <!-- Cart -->
      <template
        v-if="windowWidth >= 1406"
      >
        <div class="cart-aside">
          <section>
            <sports-cart
              :cart-items.sync="cartItems"
              :filtered-set-menu="filteredSetMenu"
              :is-loading="isLoading"
              :window-width="windowWidth"
              @remove-cart="removeCart"
              @reset-cart="resetCart"
              @create-bet="createBet"
            />

          </section>
        </div>
      </template>
      <template v-else>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-toggle.sidebar-right
          variant="primary"
          class="cart-btn btn-icon position-fixed"
          style="bottom: 5%; right: 75px; padding: 10px; overflow:visible;"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            size="16"
          />
          <b-badge
            class="position-absolute text-bold badge-glow m-0"
            style="background-color: #0ba81c; right: -7px; top: -10px;"
            pill
          >
            {{ cartItems.length }}
          </b-badge>
          <span v-if="windowWidth > 600"> BET CART </span>
        </b-button>
        <b-sidebar
          id="sidebar-right"
          bg-variant="white"
          right
          backdrop
          shadow
        >
          <sports-cart
            :cart-items.sync="cartItems"
            :filtered-set-menu="filteredSetMenu"
            :is-loading="isLoading"
            :window-width="windowWidth"
            @remove-cart="removeCart"
            @reset-cart="resetCart"
            @create-bet="createBet"
          />
        </b-sidebar>
      </template>
    </div>

    <!-- Data-Binding -->
    <div
      v-if="false"
    >
      <button @click="toggleUpdate(1)">
        자동 갱신 {{ isUpdating ? 'Stop Updating' : 'Start Updating' }}
      </button>
      <button @click="toggleUpdate(2)">
        수동 갱신
      </button>
      window width: <b> {{ windowWidth }} </b> px <br>
      inplay width: <b>{{ inplayWidth }} </b> px <br>
      <!-- Mobile: {{ isMobile }} </br> -->
      SelectedSports: {{ selectedSports }} </br>

      selectedGame: <br> <b>{{ Object.keys(selectedGame) }} </b><br>
      selectedGame: <br> <b>{{ selectedGame }} </b><br>
      filteredSelectedGame: <br> <b>{{ filteredSelectedGame }} </b><br>

      <!-- filteredSetEvents: {{ filteredSetEvents }}<br> -->
      <!-- fetchGameSetMenu: {{ fetchGameSetMenu }}<br> -->
      <!-- fetchGameSetItem: {{ fetchGameSetItem }}<br> -->

      <!-- cartItems: {{ cartItems }}<br> -->
      <!-- filteredCartEventItems: {{ filteredCartEventItems }}<br> -->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  VBToggle,
  BPagination,
  BImg,
  BSidebar,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

import {
  FETCH_SET_EVENT,
  FETCH_GAME_SET_MENU,
  FETCH_GAME_SET_ITEM,
  FETCH_GAME_SET_ODDS_CUSTOM,
} from '@/store/settings/action'
import { CLEAR_CART_ITEM, REMOVE_CART_ITEM } from '@/store/cart/mutation'
import { ADD_CART, UPDATE_CART_ITEM } from '@/store/cart/action'
import { BET } from '@/store/bet/action'

import * as moment from 'moment-timezone'
import SportsInplayGamesOdds from './Sports-Inplay-Games-Odds.vue'
import SportsCart from './Sports-Cart.vue'

// import { inplay } from './fake-db'

moment().tz('Asia/Seoul')
const cartStore = createNamespacedHelpers('cartStore')
const settingsStore = createNamespacedHelpers('settingsStore')
const crossStore = createNamespacedHelpers('crossStore')
const betStore = createNamespacedHelpers('betStore')

export default {
  name: 'SportsInplay',

  components: {
    // BSV
    BCard,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    BPagination,
    BImg,
    BSidebar,
    BSpinner,

    // Child-Components
    SportsInplayGamesOdds,
    SportsCart,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatGameTime: value => {
      const dataFormat = 'MM-DD HH:mm'
      if (!value.startDate || !value.startTime) return moment('9999-12-31 23:59:59').format(dataFormat)

      const dateTime = moment(`${value.startDate.replace(/\./g, '-')}T${value.startTime}`)
      return dateTime.format(dataFormat)
    },
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      // Width
      windowWidth: 0,
      inplayWidth: 0,
      isMobile: false,
      isLoading: false,

      // userData: JSON.parse(localStorage.getItem('userData')),
      userData: this.$store.getters['authStore/user'],

      // Games
      orgData: [],
      games: [],

      // Leagues
      selectedLeague: '',
      leagues: [],

      // Sports & Search
      selectedSports: '',
      searchText: '',

      // GamesDetail
      selectedGame: {},
      lastSelectedGame: {},

      collapsedOdds: new Map(),
      collapsedOddsTemp: new Map(),

      // paginatedItems
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0,

      // fakeDb
      isUpdating: false,
      intervalId: null,
    }
  },
  computed: {
    ...cartStore.mapGetters([
      'cartItems',
    ]),
    ...settingsStore.mapGetters([
      'fetchSetEvent',
      'fetchGameSetMenu',
      'fetchGameSetItem',
      'fetchGameSetOddsCustom',
    ]),
    ...crossStore.mapGetters([
      'crossFilter',
    ]),
    formatValue() {
      return value => {
        // console.log(value)
        if (!value) return
        if (this.windowWidth < 400) return `${value.slice(0, 3)}`
        if (this.windowWidth < 500) return `${value.slice(0, 6)}`
        return value
      }
    },
    totalLeagues() {
      return this.leagues.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0) || 0
    },
    filteredSetMenu() {
      const result = this.fetchGameSetMenu.filter(item => item.useYn === 'Y' && item.name === 'Sports-Inplay')
      return result.length > 0 ? result : [{}]
    },
    filteredSports() {
      const result = this.fetchGameSetItem
        .filter(item => item.useYn === 'Y' && item.menuName === 'Sports-Inplay' && item.site === this.$site)
        .map(item => ({ text: item.nameCustom, value: item.name }))
      return result.length > 0 ? result : [{}]
    },
    filteredSetEvents() {
      const result = this.fetchSetEvent.filter(item => item.active === 'true' && item.gameName === 'Sports-Inplay')
      return result.length > 0 ? result : [{}]
    },
    filteredFullTimeResult() {
      return value => {
        const odd = value.find(item => item.id === 1777)
        if (!odd || !odd.participants) {
          return []
        }
        return odd.participants
      }
    },
    filteredCartItems() {
      // oddsId만 추출
      const oddsItems = this.cartItems.map(item => item.oddsId)

      // homeAway가 'Bonus'인 경우의 leagueName 추출
      const eventItems = this.cartItems
        .filter(item => item.homeAway === 'Bonus')
        .map(item => item.leagueName)

      return {
        oddsItems,
        eventItems,
      }
    },
    // filtered Items
    filteredItems() {
      let filteredItems = this.games || []

      // 선택된 리그에 해당하는 경기만 필터링합니다.
      if (this.selectedLeague !== 'all') {
        filteredItems = filteredItems.filter(item => item.leagueName === this.selectedLeague)
      }

      // 검색어로 필터링합니다.
      const searchText = this.searchText.toLowerCase().trim()
      if (searchText) {
        const lowerCasedSearchText = searchText.toLowerCase()
        filteredItems = filteredItems.filter(item => item.leagueName.toLowerCase().includes(lowerCasedSearchText)
          || item.homeName.toLowerCase().includes(lowerCasedSearchText)
          || item.awayName.toLowerCase().includes(lowerCasedSearchText))
      }

      return filteredItems
    },
    // filtered Items => Pagination Items
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      const paginatedData = this.filteredItems.slice(start, end)

      this.totalRows = this.filteredItems.length
      this.pageFrom = start + 1
      this.pageTo = start + paginatedData.length
      this.pageOf = this.totalRows

      return paginatedData
    },
    filteredSelectedGame() {
      const eventId = this.selectedGame[this.selectedSports]
      return this.paginatedItems.find(item => item.eventId === eventId)
    },

    isCollapsedOdds() {
      if (this.selectedGame && this.selectedGame && this.selectedGame.eventId) {
        const { eventId } = this.selectedGame
        return this.collapsedOdds.get(eventId) || []
      }
      return []
    },
  },
  watch: {
    currentPage() {
      this.handleSelectedGame(this.paginatedItems[0])
    },
    isMobile(newVal, oldVal) {
      if (!newVal && oldVal) {
        // 모바일 모드에서 일반 모드로 변경될 때
        const sport = this.selectedSports
        if (!this.selectedGame[sport]) {
          if (this.lastSelectedGame[sport]) {
            this.$set(this.selectedGame, sport, this.lastSelectedGame[sport])
          } else if (this.games.length > 0) {
            const defaultGame = this.paginatedItems[0]
            this.$set(this.selectedGame, sport, defaultGame.eventId)
          }
        }
      }
    },
    games() {
      this.handlePointUpDown()
    },
  },
  created() {
    // console.log('create()', this.componentName)
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')

    // Resize는 Mounted해야함
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.init()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    const site = this.$site
    this.$socket.off(`getInplayUpdate:${site}`)
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  methods: {
    // Setting
    ...settingsStore.mapActions({
      $fetchSetEvent: FETCH_SET_EVENT,
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
      $fetchGameSetItem: FETCH_GAME_SET_ITEM,
      $fetchGameSetOddsCustom: FETCH_GAME_SET_ODDS_CUSTOM,
    }),
    // Cart
    ...cartStore.mapMutations({
      clearCart: CLEAR_CART_ITEM,
    }),
    ...cartStore.mapActions({
      addToCart: ADD_CART,
      updateCart: UPDATE_CART_ITEM,
      removeCartItem: REMOVE_CART_ITEM,
    }),
    ...betStore.mapActions({
      createBetHistory: BET,
    }),

    // Settings
    onResize() {
      this.windowWidth = window.innerWidth
      this.isMobile = window.innerWidth <= 834
      this.inplayWidth = (document.querySelector('.inplay-wrap') || { offsetWidth: 0 }).offsetWidth
    },

    //
    init() {
      // console.log(this.componentName, 'init()')

      this.selectedLeague = 'all'
      this.selectedSports = 'soccer'
      this.isLoading = true

      this.setMenu()
      this.fetchData()
      this.resetCart()

      // this.$socket.off('getInplayUpdate')
      const site = this.$site
      this.$socket.on(`getInplayUpdate:${site}`, data => {
        try {
          if (data) {
            this.orgData = JSON.parse(data)
            this.fetchGames()
            this.updateCardItems()

            console.log(`${this.orgData[1].length} 업데이트됨`)
            console.log(this.orgData[1])
          } else {
            console.error('데이터를 받지 못했습니다')
          }
        } catch (error) {
          console.error('데이터 파싱 오류: ', error)
        }
      })
    },
    async setMenu() {
      await this.$fetchGameSetItem(this.$site)
      await this.$fetchSetEvent(this.$site)
    },
    async fetchData() {
      console.log(this.componentName, 'fetchData()')
      // if (process.env.NODE_ENV === 'development') {
      //   this.fakeFetchData()
      //   return
      // }

      await this.$socket.emit('handleMenuEntry', {
        site: this.$site,
        userid: this.userData.userid,
        menu: 'Sports-Inplay',
        menuCategory: this.selectedSports,
      })
      await this.$socket.emit('getInplayFirst', {
        site: this.$site,
        userid: this.userData.userid,
        sports: this.selectedSports,
      }, data => {
        // try {
        //   if (data) {
        //     this.orgData = JSON.parse(data)
        //     this.fetchGames()
        //
        //     console.log('leagues: ', this.orgData[0])
        //     console.log('games: ', this.orgData[1])
        //     console.log(`${this.orgData[1].length} 업데이트됨`)
        //   } else {
        //     console.error('데이터를 받지 못했습니다')
        //   }
        // } catch (error) {
        //   console.error('데이터 파싱 오류: ', error)
        // }
        try {
          if (data && data !== 'null' && data !== 'undefined') {  // data가 null, undefined일 경우도 처리
            this.orgData = JSON.parse(data);

            if (this.orgData && Array.isArray(this.orgData) && this.orgData.length > 1) {
              this.fetchGames()

              console.log('leagues: ', this.orgData[0])
              console.log('games: ', this.orgData[1])
              console.log(`${this.orgData[1].length} 업데이트됨`)
            } else {
              console.warn('데이터가 예상한 형식이 아닙니다')
            }
          } else {
            console.error('데이터를 받지 못했습니다')
          }
        } catch (error) {
          console.error('데이터 파싱 오류: ', error)
        }
      })
    },
    fetchGames() {
      // 배열 해체 (Array Destructuring)
      [this.leagues, this.games] = this.orgData
      this.isLoading = false

      if (!this.isMobile && !this.selectedGame[this.selectedSports]) {
        this.handleSelectedGame(this.games[0])
      }
    },
    // fakeFetchData() {
    //   console.log(this.componentName, 'fakeFetchData()')
    //   console.log(this.componentName, 'fakeFetchData() selectedLeague', this.selectedLeague)
    //   console.log(this.componentName, 'fakeFetchData() selectedSports', this.selectedSports)

    //   clearInterval(this.intervalId)

    //   this.games = inplay.find(obj => Object.prototype.hasOwnProperty.call(obj, this.selectedSports))[this.selectedSports] || []
    //   this.leagues = Object.values(this.games.reduce((acc, game) => {
    //     const { leagueName } = game
    //     if (!acc[leagueName]) {
    //       acc[leagueName] = { name: leagueName, count: 0 }
    //     }
    //     acc[leagueName].count += 1
    //     return acc
    //   }, {}))
    //   this.isLoading = false

    //   console.log(this.selectedSports, ':', this.games)
    //   console.log('leaguess :', this.leagues)

    //   if (!this.isMobile && !this.selectedGame[this.selectedSports]) {
    //     this.handleSelectedGame(this.games[0])
    //   }
    // },
    toggleUpdate(type) {
      if (type === 1) {
        if (this.isUpdating) {
          clearInterval(this.intervalId)
          this.isUpdating = false
        } else {
          this.fakeUpdateFetchData() // 바로 첫 업데이트 실행
          this.intervalId = setInterval(this.fakeUpdateFetchData, 3000)
          this.isUpdating = true
        }
      }
      if (type === 2) {
        this.fakeUpdateFetchData()
      }
    },
    fakeUpdateFetchData() {
      // console.log('call.... fakeUpdateFetchData')
      const updatedData = this.games.map(game => ({
        ...game,
        odds: game.odds.map(odd => ({
          ...odd,
          participants: odd.participants.map(participant => {
            const currentValue = parseFloat(participant.value_eu)
            const randomValue = (Math.random() * 1.5).toFixed(3) // 0.0 ~ 1.5 사이의 랜덤 값 생성
            const addOrSubtract = Math.random() < 0.5 ? -1 : 1 // 더할지 뺄지 결정
            return {
              ...participant,
              value_eu: (currentValue + (randomValue * addOrSubtract)).toFixed(3),
            }
          }),
        })),
      }))
      // console.log('updatedData :', updatedData)
      this.games = updatedData
      this.updateCardItems()
    },

    // Handle
    handleImageUrl(value) {
      const imageMapping = {
        soccer: item => item.split(' ')[0].trim(),
        basketball: () => 'default',
        baseball: () => 'default', // item.split(':')[0].trim(),
        tennis: () => 'default', // item.match(/\(([^)]+)\)/)[1],
        volleyball: () => 'default',
        football: () => 'default',
        hockey: () => 'default',
        esports: item => item.split(' ')[0].trim(),
        default: () => 'default',
      }
      const selectedSportHandler = imageMapping[this.selectedSports] || imageMapping.default
      const imageName = selectedSportHandler(value || 'default')

      const url = `https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/${imageName}.svg`
      return url
    },
    handleImageError(e) {
      e.target.src = 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/default.svg'
    },
    handleLeagues(leagueName) {
      this.selectedLeague = leagueName // 선택된 리그 저장
    },
    handleRadioSports(value) {
      console.log('sports:::', value)
      this.orgData = []
      this.leagues = []
      this.searchText = ''
      this.currentPage = 1
      this.crossFilter.searchText = ''
      this.selectedSports = value
      this.fetchData()
      this.handleSelectedGame(this.paginatedItems[0])
    },
    handleKeyupSearchText() {
      this.searchText = document.querySelector('#searchTextInput').value
    },
    handleSelectedGame(game) {
      const sport = this.selectedSports
      const { eventId } = game

      if (this.isMobile) {
        // 모바일 모드에서는 토글 방식
        if (this.selectedGame[sport] === eventId) {
          this.$delete(this.selectedGame, sport)
        } else {
          this.$set(this.selectedGame, sport, eventId)
        }
        this.$set(this.lastSelectedGame, sport, eventId) // 마지막 선택된 게임 저장
      } else {
        // 일반 모드에서는 단순 변경
        this.$set(this.selectedGame, sport, eventId)
        this.$set(this.lastSelectedGame, sport, eventId) // 마지막 선택된 게임 저장
      }
    },
    handleCollapsedOdds(eventId, oddsId) {
      const odds = this.collapsedOdds.get(eventId) || []
      const index = odds.indexOf(oddsId)
      if (index > -1) {
        odds.splice(index, 1) // 이미 존재하면 제거
      } else {
        odds.push(oddsId) // 존재하지 않으면 추가
      }
      // 반응성 유지를 위해 새 Map 객체를 생성하여 업데이트
      const updatedMap = new Map(this.collapsedOdds)
      updatedMap.set(eventId, [...odds])
      this.collapsedOdds = updatedMap
    },
    handlePointUpDown() {
      const points = this.$el.querySelectorAll('.point')
      points.forEach(point => {
        const el = point // el로 복사하여 사용
        const currentPoint = parseFloat(el.textContent)
        const dataPoint = parseFloat(el.dataset.point)
        const parentEl = el.closest('.btn') // closest 메소드를 사용하여 버튼을 찾습니다.
        // 초기값
        if (!dataPoint) {
          el.dataset.point = currentPoint
        }

        if (currentPoint > dataPoint) {
          parentEl.classList.add('point-up')
          parentEl.classList.remove('point-down')
          el.dataset.point = currentPoint
        } else if (currentPoint < dataPoint) {
          parentEl.classList.add('point-down')
          parentEl.classList.remove('point-up')
          el.dataset.point = currentPoint
        } else {
          parentEl.classList.remove('point-up', 'point-down')
        }
      })

      setTimeout(() => {
        document.querySelectorAll('.point-up, .point-down').forEach(el => {
          const priceClasses = Object.values(el.classList).filter(c => c.startsWith('point-'))
          el.classList.remove(...priceClasses)
        })
      }, 2000)
    },

    /* Cart Methods */
    addCart(gameRow, odd, participant) {
      let item = {}
      // 날짜와 시간이 없을 경우 현재 시간을 사용
      const gameDate = gameRow.startDate && gameRow.startTime ? `${gameRow.startDate} ${gameRow.startTime}` : moment().format('YYYY.MM.DD HH:mm')

      if (gameRow === 'Bonus') {
        const bId = `B00${Date.now()}`

        // console.log('gameRow ::', gameRow)
        // console.log('odd ::', odd)
        // console.log('participant ::', participant)
        item = {
          site: this.$site,
          userid: this.userData.userid,
          nickname: this.userData.nickname,
          category: 'Sports-Inplay',
          sports: this.selectedSports,
          gameId: bId, // eventId
          gameDate,
          leagueName: odd.eventName, // 보너스 이름
          homeName: '', // homeName
          awayName: '', // awayName
          gameType: gameRow, // gameType
          oddsHome: 0,
          oddsDraw: 0,
          oddsAway: 0,
          oddsId: bId, // oddsId
          odds: participant, // bonus odds
          name: odd.minFolds.toString(), // options handicap
          homeAway: gameRow, // choose bonus
        }
      } else if (odd.participants.length > 2) {
        item = {
          site: this.$site,
          userid: this.userData.userid,
          nickname: this.userData.nickname,
          category: 'Sports-Inplay',
          sports: this.selectedSports,
          gameId: gameRow.eventId, // eventId
          mId: gameRow.mId,
          gameDate,
          leagueName: gameRow.leagueName, // leagueName
          homeName: gameRow.homeName, // homeName
          awayName: gameRow.awayName, // awayName
          gameType: odd.name, // gameType
          oddsHome: odd.participants[0].value_eu,
          oddsDraw: odd.participants[1].value_eu,
          oddsAway: odd.participants[2].value_eu,
          oddsId: participant.id, // oddsId
          odds: participant.value_eu, // odds
          name: participant.handicap, // options handicap
          homeAway: participant.name, // choose home,draw,away
        }
      } else {
        item = {
          site: this.$site,
          userid: this.userData.userid,
          nickname: this.userData.nickname,
          category: 'Sports-Inplay',
          sports: this.selectedSports,
          gameId: gameRow.eventId, // eventId
          mId: gameRow.mId,
          gameDate,
          leagueName: gameRow.leagueName, // leagueName
          homeName: gameRow.homeName, // homeName
          awayName: gameRow.awayName, // awayName
          gameType: odd.name, // gameType
          oddsHome: odd.participants[0].value_eu,
          // oddsDraw: odd.participants[0].handicap,
          oddsAway: odd.participants[1].value_eu,
          oddsId: participant.id, // oddsId
          odds: participant.value_eu, // odds
          name: participant.handicap, // options handicap
          homeAway: participant.name, // choose home,draw,away
        }
      }
      this.addToCart(item)
    },
    removeCart(item) {
      this.removeCartItem(item)
    },
    resetCart() {
      this.clearCart()
    },
    createBet(item) {
      // console.log('createBet', item)
      this.createBetHistory(item)
    },
    updateCardItems() {
      this.updateCart(this.games)
    },
  },
}
</script>

<style scoped>
/* app-inplay > common setting */
.app-inplay * {
  color: white;
  font-size: 0.9rem;
}
.app-inplay .card {
  margin-bottom: 5px;
}
.app-inplay .card-body {
  padding: 0.2rem;
}

/* app-inplay > grid setting */
.inplay-wrap {
  display: flex;
}
.inplay-wrap .leagues-nav,
.inplay-wrap .cart-aside {
  flex: 0 0 285px; /* 고정된 너비 */
  width: 285px;
  max-width: 285px !important;
}
.inplay-wrap .games-main {
  flex: 1;
}
.inplay-wrap .leagues-nav section {
  margin-right: 5px;
}
.inplay-wrap .cart-aside section {
  margin-left: 5px;
}

/* Leagues-Nav */
.leagues-nav .league {
  display: flex;
  align-items: center;
}
.leagues-nav .league .league-name {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leagues-nav .league .league-symbol svg,
.leagues-nav .league .league-symbol img {
  height: 15px;
  width: 20px;
  margin-right: 5px;
}
.leagues-nav .league .league-count {
  margin-left: auto;
  width: 35px;
}

/* games-nav */
.games-nav > div {
  padding: 15px;
}
.games-nav .games-sports .form-group {
  margin-bottom: 0px;
}

.search-result {
  padding: 15px;
  text-align: center;
  font-size: 1.1em;
  font-weight: 500; /* 글자를 두껍게 설정 */
}

/* event-wrap */
.bonus-wrap {
  padding-top:5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 15px;

  margin-top: 5px;
  margin-bottom: 5px;
}
.bonus-wrap .btn-wrap {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 0.7px;
}
.bonus-wrap .btn-wrap button {
  flex: 1;
  /* white-space: nowrap; */
}

/* games-wrap */
.games-wrap {
  /* padding-left: 15px; */
  /* padding-right: 15px; */

  display: flex;
  align-items: flex-start;
}
.games-wrap > div {
  flex: 1;
  min-width: 0; /* 기본 너비를 설정하여 요소가 줄어들 수 있도록 함 */
  position: relative;
  z-index: 0;
}
.app-inplay .games-wrap > div:first-child {
  padding-left: 15px;
  margin-right: 6.5px;
}
.app-inplay  .games-wrap > div:last-child {
  /* padding-right: 15px; */
  margin-left: 6.5px;
}

/* games */
.games .game {
  margin-bottom: 0.5rem;
}

/* games > game-header */
.games .game .game-header {
  display: flex;
  justify-content: space-between;

  position: relative;
  z-index: 2;

  width: 100%;
  padding: 5px;
  margin: 0 auto;
  background-color: #161d31;
  border: 1px solid rgba(160, 160, 160, 0.2);
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
}
.games .game .game-header .league-symbol svg,
.games .game .game-header .league-symbol img {
  height: 15px;
  width: 20px;
  margin-right: 5px;
}

/* games > game-contents */
.games .game .game-contents {
  background-color: #283046;
  border-radius: 0.428rem;
  padding: calc(20px + 15px) 7px 7px 7px ; /* header-contents 겹치게 */
  margin-top: -20px;
  box-shadow: 1px 1px 8px rgba(0,0,0,.3);
  position: relative;
  z-index: 1;
}

/* games > game-contents > board-header */
.games .game .game-contents .board-header {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
}
.games .game .game-contents .board-header > div {
  width: 35%;
  padding: 5px;
  border: 1px solid rgba(160, 160, 160, 0.2);
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  background-color: #161d31;
  text-align: center;
}

/* games > game-contents > board-contents */
.games .game .game-contents .board-contents {
  background-color: #283046;
  border-radius: 0.428rem;
  padding: calc(20px + 5px) 7px 7px 7px ; /* header-contents 겹치게 */
  margin-top: -20px; /* header-contents 겹치게 */
  box-shadow: 1px 1px 8px rgba(0,0,0,.3);
  overflow: hidden; /* box-shadow를 border-radius에 맞춰 둥글게 처리 */
}
.games .game .game-contents .board-contents .game-time {
  text-align: center;
  margin-top: -20px;
}
.games .game .game-contents .board-contents .game-score {
  display: flex;
  justify-content: space-evenly;
  white-space: nowrap;
  margin-bottom: 0.3rem;
}
.games .game .game-contents .board-contents .game-score span {
  font-size: 1.7rem;
  font-weight: bold; /* 글자를 두껍게 설정 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* 그림자 효과 추가 */
}
.games .game .game-contents .board-contents .odds .btn-wrap {
  display: grid;
  justify-content: center;
  grid-template-columns: 42.5% 15% 42.5%; /* 각 버튼의 비율 설정 */
  gap: 3px; /* 버튼 간의 간격 (원하는 경우) */
}
.games .game .game-contents .board-contents .odds .btn-wrap button {
  display: flex;
  padding: 0.5rem;
  margin: 0.05rem; /* 버튼 사이 간격 */
  border-radius: 0.2rem;
}
.games .game .game-contents .board-contents .odds .btn-wrap button span.homeAway {
  flex: 7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.games .game .game-contents .board-contents .odds .btn-wrap button span.point {
  flex: 3;
  color: rgb(255, 198, 139);
}
.games .game .game-contents .board-contents .odds .btn-wrap button:first-child span.homeAway{
  text-align: left;
}
.games .game .game-contents .board-contents .odds .btn-wrap button:first-child span.point{
  text-align: right;
}
.games .game .game-contents .board-contents .odds .btn-wrap button:last-child span.homeAway{
  text-align: right;
}
.games .game .game-contents .board-contents .odds .btn-wrap button:last-child span.point{
  text-align: left;
}

/* games > game-selected */
.games .game.game-selected .game-contents .board-header div {
  position: relative;
}
.games .game.game-selected .game-contents .board-header div::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border: 1px solid #d369a4;
  border-radius: 7px;
  box-shadow: 0 0 3px 2px #bf53b1;
  z-index: -1;
}
.games .game.game-selected .game-contents .board-contents {
  border: 1px solid #d369a4 !important;
  box-shadow: 0 0 3px 2px #bf53b1 !important;
}
.games .game.game-selected .game-contents .games-sub {
  width: 100%;
  background-color: #0f0f11;
  border-radius: 0.428rem;
  padding: 1rem 0rem;
}

/* 버튼 선택 애니메이션 효과 정의 */
.btn-wrap button.cart-selected {
  color: white !important;
  background: linear-gradient(to right, #bf53b1, #d369a4, #ff7c6e, #bf53b1);
  background-size: 400% 100% !important; /* 배경 크기를 세 배로 설정 */
  animation: gradient-slide 5s linear 1 forwards !important; /* 애니메이션 속성 적용 */
}
@keyframes gradient-slide {
  0% { background-position: 0 50%; }
  100% { background-position: 100% 50%; }
}

/* point up&down */
.games .btn-wrap button {
  position: relative;
}
.games .btn-wrap button .point::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  transition: opacity 0.5s; /* 0.5초 동안 서서히 사라지도록 */
}
.games .btn-wrap button.point-up .point::after {
  right: 1px;
  top: 1px;
  border-width: 0px 7px 7px 0px; /* 상, 우, 하, 좌 */
  transform: rotate(180deg); /* 위쪽 화살표를 나타내기 위해 45도 회전 */
  border-color: transparent transparent #ff0000 transparent; /* 화살표 색상 */
  animation: disappear 1.5s forwards; /* 1초 동안 애니메이션이 실행되고 끝난 후 사라지도록 */
}
.games .btn-wrap button.point-up:last-child .point::after {
  left: 1px;
  top: 1px;
  border-width: 0px 7px 7px 0px; /* 상, 우, 하, 좌 */
  transform: rotate(90deg); /* 위쪽 화살표를 나타내기 위해 45도 회전 */
  border-color: transparent transparent #ff0000 transparent; /* 화살표 색상 */
  animation: disappear 1.5s forwards; /* 1초 동안 애니메이션이 실행되고 끝난 후 사라지도록 */
}
.games .btn-wrap button.point-down .point::after {
  right: 1px;
  bottom: 1px;
  border-width: 0px 7px 7px 0px; /* 상, 우, 하, 좌 */
  transform: rotate(270deg); /* 아래쪽 화살표를 나타내기 위해 -45도 회전 */
  border-color: transparent transparent #0000ff transparent; /* 화살표 색상 */
  animation: disappear 1.5s forwards; /* 1초 동안 애니메이션이 실행되고 끝난 후 사라지도록 */
}
.games .btn-wrap button.point-down:last-child .point::after {
  left: 1px;
  bottom: 1px;
  border-width: 0px 7px 7px 0px; /* 상, 우, 하, 좌 */
  transform: rotate(0deg); /* 아래쪽 화살표를 나타내기 위해 -45도 회전 */
  border-color: transparent transparent #0000ff transparent; /* 화살표 색상 */
  animation: disappear 1.5s forwards; /* 1초 동안 애니메이션이 실행되고 끝난 후 사라지도록 */
}
.games .btn-wrap button:not(.point-up, .point-down) .point::after {
  content: none;
}
.games .btn-wrap button.btn.disabled,
.games .btn-wrap button.btn:disabled {
  opacity: 1 !important;
}
@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 834px) {

}
</style>
